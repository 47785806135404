const tt1_ten = {
    // Activity1: 1 - 54 images, Activity2: 55 - 59 Images 
    summary: 'Blankets provided to poor women and widows.|Activity conducted at: Unnava village.\nBlankets provided to poor women and widows.|Activity conducted at: Naidupeta village.',
    path: 'activities/2021-Present/2021/12-19-2021/images/',
    lastIndex: '59'
};


const tt1_nine = {
    summary: 'Sarees provided to poor women and widows.|Activity conducted at: Chebrolu village.',
    path: 'activities/2021-Present/2021/11-04-2021/images/',
    lastIndex: '92'
};

const tt1_eight = {
    // Activity1: 1 - 41 images, Activity2: 42 - 45 Images, Activity3: 46 - 96 Images
    summary: 'Sarees provided to poor women and widows.|Activity conducted at: Obulnaidupalem village.\nGroceries provided to old age home.|Activity conducted at: Obulnaidupalem village.\nSarees provided to poor women and widows.|Activity conducted at: Mothadaka village.',
    path: 'activities/2021-Present/2021/10-31-2021/images/',
    lastIndex: '96'
};

const tt1_seven = {
    // Activity1: 1 - 48 images, Activity2: 49 - 109 Images
    summary: 'Sarees provided to poor women and widows.|Activity conducted at: Gorantla village.\nSarees provided to poor women and widows.|Activity conducted at: Jonnalagadda village.',
    path: 'activities/2021-Present/2021/10-24-2021/images/',
    lastIndex: '109'
};

const tt1_six = {
    summary: 'Sarees and blankets provided to poor women and widows.|Activity conducted at: Lam village.',
    path: 'activities/2021-Present/2021/09-05-2021/images/',
    lastIndex: '34'
};

const tt1_five = {
    summary: 'Blankets, medicines and groceries provided to specially abled people.|Activity conducted at: Ponnuru.',
    path: 'activities/2021-Present/2021/08-08-2021/images/',
    lastIndex: '18'
};

const tt1_four = {
    summary: 'Fridge provided to old age home.|Activity conducted at: Guntur.',
    path: 'activities/2021-Present/2021/07-21-2021/images/',
    lastIndex: '9'
};

const tt1_three = {
    summary: 'Breakfast to sanitation workers.|Activity conducted at: Guntur.\nGroceries provided to orphan children.|Activity conducted at: Mangalagiri.\nClothes, provisions, and diapers provided to specially abled orphan women.|Activity conducted at: Nidubrolu.',
    path: 'activities/2021-Present/2021/07-11-2021/images/',
    lastIndex: '43'
};

const tt1_two = {
    summary: 'Wheelchair, diapers, and medicines provided to old age home.|Fridge and groceries provided to child orphanage.|Activity conducted at: Pattabhipuram.',
    path: 'activities/2021-Present/2021/03-26-2021/images/',
    lastIndex: '31'
};

const tt1_one = {
    summary: 'Windows and doors installed at child orphanage.|Medicines and groceries provided to children.|Activity conducted at: Etukuru.',
    path: 'activities/2021-Present/2021/03-05-2021/images/',
    lastIndex: '92'
};

const tt1_zero = {
    summary: 'Sarees provided to agricultural workers and dhotis provided to poor farmers for Sankranti in recognition of their efforts in maintaining uninterrupted food supply during pandemic.|Activity conducted at: Pedapalem.',
    path: 'activities/2021-Present/2021/01-11-2021/images/',
    lastIndex: '33'
};

const tt2_twenty = {
    summary: 'Provided water heater, pressure cooker and rice to an oldage home.|Activity conducted at: Tummalacheruvu village, Palnadu district.',
    path: 'activities/2021-Present/2022/12-23-2022/images/',
    lastIndex: '6'
};

const tt2_nineteen = {
    // Activity1: 1 - 8 images, Activity2: 9 - 14 Images, Activity3: 15 - 21 Images, Activity4: 22 - 24 Images, Activity5: 25 - 28 Images, Activity6: 29 - 35 Images
    summary: 'Provided breakfast and lunch to 150 specially abled children and their attendants.|Activity conducted at: Addateegala.\nProvided dinner to children at an orphanage.|Activity conducted at: Etukuru.\nProvided lunch to specially abled children at St. Anns Manovikas Kendra.|Activity conducted at: Guntur.\nProvided dinner to children at an orphanage.|Activity conducted at: Brahmanakoduru.\nProvided dinner to children at St. Anns AIDS care and support center.|Activity conducted at: Machavaram.\nProvided dinner to children at an orphanage.|Activity conducted at: Chinakakani.',
    path: 'activities/2021-Present/2022/12-03-2022/images/',
    lastIndex: '35'
};

const tt2_eighteen = {
    summary: 'Provided water purifier and clothes to children at St. Anns AIDS care and support center.|Activity conducted at: Machavaram.',
    path: 'activities/2021-Present/2022/12-01-2022/images/',
    lastIndex: '15'
};

const tt2_seventeen = {
    // Activity1: 1 - 10 images, Activity2: 11 - 18 Images, Activity3: 19 - 29 Images, Activity4: 30 - 39 Images
    summary: 'Provided food for children at an orphanage.|Activity conducted at: Etukuru.\nProvided food for children at an orphanage.|Activity conducted at: Brahmana Koduru.\nProvided water heater and food for children at a hostel for specially abled children.|Activity conducted at: Guntur.\nProvided food for poor children and old aged people in a village.|Activity conducted at: Thimmapuram.',
    path: 'activities/2021-Present/2022/11-25-2022/images/',
    lastIndex: '39'
};

const tt2_sixteen = {
    summary: 'Provided sarees, lungis and towels to poor and old aged people living in tribal villages.|Activity conducted at: Alluri Sitharama Raju district.',
    path: 'activities/2021-Present/2022/10-24-2022/images/',
    lastIndex: '24'
};
const tt2_fifteen = {
    summary: 'Provided dinner for 100 rag pickers living at Guntur dump yard.|Activity conducted at: Naidupeta, Guntur.',
    path: 'activities/2021-Present/2022/10-05-2022/images/',
    lastIndex: '14'
};

const tt2_fourteen = {
    summary: 'Provided 300 blankets to poor and old aged people living in tribal villages.|Activity conducted at: Alluri Sitharama Raju district.',
    path: 'activities/2021-Present/2022/10-03-2022/images/',
    lastIndex: '47'
};

// Activity1: 1 - 8 images, Activity2: 9 - 11 Images
const tt2_thirteen = {
    summary: 'Felicitated teachers teaching, training and taking care of specially abled children.|Activity conducted at: Guntur.\nFelicitated Sri. S. A. Rahiman, Ex. Principal, R K High School, Repalle and provided health care support to him.|Activity conducted at: Kollipara.',
    path: 'activities/2021-Present/2022/09-05-2022/images/',
    lastIndex: '12'
};

const tt2_twelve = {
    summary: 'Celebrated 75th anniversary of India’s independence with specially abled children. Provided sewing machine, notebooks and sweets to the children.|Activity conducted at: St Ann\'s Manovikas Kendra Spl School, Guntur.',
    path: 'activities/2021-Present/2022/08-15-2022/images/',
    lastIndex: '8'
};

const tt2_eleven = {
    summary: 'Felicitated 30 war widows and war mothers of Indian Armed Forces.|Activity conducted at: The office of the Zilla Sainik Welfare Officer, Guntur.',
    path: 'activities/2021-Present/2022/08-11-2022/images/',
    lastIndex: '9'
};

const tt2_ten = {
    summary: 'Provided dinner (Annadaanam) for 250 poor people. The activity was sponsored by Dr. Ancha Srinivasan in memory of his father Sri Ancha Raghavaiah.|Activity conducted at: Budampadu.',
    path: 'activities/2021-Present/2022/07-23-2022/images/',
    lastIndex: '5'
};

const tt2_nine = {
    summary: 'Provided sports wheelchair for specially abled persons to Mr. K. Gopi, the Captain of PARA Sitting Cricket team of Andhra Pradesh. The activity was presided over by Sri. L. Siva Sankar, the honorable collector of Palnadu district.|Activity conducted at: District collectors office, Narsaraopet, Palnadu district.',
    path: 'activities/2021-Present/2022/07-18-2022/images/',
    lastIndex: '16',
    updates: [
        {
            updateIndex: 0,
            summary: 'Mr. K Gopi has won gold medal in Para Shooting Competitions held at Visakhapatnam, Andhra Pradesh.',
            path: 'activities/2021-Present/2022/07-18-2022/updates/1/',
            lastIndex: '3'
        }
    ]
};

//Activity1: 1-4 images, Activity2: 5-19 images
const tt2_eight = {
    summary: 'Provided food to 100 specially abled people and orphans of Amma Ashram. Provided food to 150 homeless people and orphans.|Activity conducted at: Seetharam Nagar, Guntur.\nProvided food to 250 people living in slum.|Activity conducted at: Dasari Palem, Guntur.',
    path: 'activities/2021-Present/2022/06-05-2022/images/',
    lastIndex: '19'
};

const tt2_seven = {
    summary: 'Provided sarees to widows and old aged women.|Activity conducted at: Namburu, Guntur.',
    path: 'activities/2021-Present/2022/05-01-2022/images/',
    lastIndex: '18'
};

//Activity1: 1-28 Images, Activity2: 29,30 Images
const tt2_six = {
    summary: 'Provided exam-pads and geometry boxes to the students of Social Welfare girls hostels.|Activity conducted at: Rajagarithota, Guntur.\nProvided groceries to children of TUCH Orphanage.|Activity conducted at: Akulavari Thota Agraharam, Guntur.',
    path: 'activities/2021-Present/2022/04-14-2022/images/',
    lastIndex: '28'
};

const tt2_five = {
    summary: 'Provided sarees, dhotis and towels to stone crusher workers.|Activity conducted at: Boyapalem.',
    path: 'activities/2021-Present/2022/04-02-2022/images/',
    lastIndex: '30'
};

const tt2_four = {
    summary: 'Provided computer CPU, groceries and medical masks to children at CHILD orphanage.|Activity conducted at: Brahmana Koduru.',
    path: 'activities/2021-Present/2022/03-01-2022/images/',
    lastIndex: '11'
};

const tt2_three = {
    summary: 'Supported the tution fee payment for a student pursuing first year medicine.|Activity conducted at: Warangal.',
    path: 'activities/2021-Present/2022/02-11-2022/images/',
    lastIndex: '4'
};

const tt2_two = {
    summary: 'Provided fridge to St. Anns Manovikas Kendra hostel for children with special needs.|Activity conducted at: Guntur.',
    path: 'activities/2021-Present/2022/02-01-2022/images/',
    lastIndex: '8'
};

// Activity1: 1 - 17 images, Activity2: 18 - 34 Images
const tt2_one = {
    summary: 'Sarees provided to agricultural workers and dhotis provided to tenant farmers.|Activity conducted at: Anamarlapudi.\nSarees provided to agricultural workers and dhotis provided to tenant farmers.|Activity conducted at: Siripuram.',
    path: 'activities/2021-Present/2022/01-14-2022/images/',
    lastIndex: '34'
};

const tt2_zero = {
    summary: 'Groceries provided to ragpicker families who reside at the city dump yard.|Activity conducted at: Guntur.',
    path: 'activities/2021-Present/2022/01-01-2022/images/',
    lastIndex: '12'
};

const tt3_fourteen = {
    summary: 'Felicitated children who did well in spoken english class at Zilla Parishad High School. The students were learning from Mr. Hari Krishna Patcharu. He was among the few members selected globally for U.S. Department of State\'s FullBright Teaching Excellence and Achievement Program. He participated in and successfully completed the program.|Activity conducted at: Ilavaram Village, Bhattiprolu',
    path: 'activities/2021-Present/2023/11-14-2023/images/',
    lastIndex: '29'
};

const tt3_thirteen = {
    summary: 'Provided blankets to poor and old aged people.|Activity conducted at: Vizianagaram, Parvathipuram and Srikakulam',
    path: 'activities/2021-Present/2023/10-10-2023/images/',
    lastIndex: '25'
};

const tt3_twelve = {
    summary: 'Provided water drums to 85 poor families.|Activity conducted at: Budamgunta',
    path: 'activities/2021-Present/2023/09-24-2023/images/',
    lastIndex: '13'
};

const tt3_eleven = {
    summary: 'Provided 3 fans, 3 cots and medicines to care center caring for children with AIDS, specially abled children and orphan children.|Activity conducted at: Mungamuru',
    path: 'activities/2021-Present/2023/08-20-2023/images/',
    lastIndex: '6'
};

const tt3_ten = {
    summary: 'Provided groceries for 1 month to children at St. Anns AIDS care and support center.|Activity conducted at: Machavaram',
    path: 'activities/2021-Present/2023/07-16-2023/images/',
    lastIndex: '5'
};

const tt3_nine = {
    summary: 'Provided dietary needs for 2 para athletes participating in World Para-Athletics Champonships 2023 held in Paris.|Activity conducted at: Bengaluru and Hyderabad',
    path: 'activities/2021-Present/2023/06-12-2023/images/',
    lastIndex: '5'
};

const tt3_eight = {
    summary: 'Felicitated children at an orphanage who did well in 10th class board examinations.|Activity conducted at: Etukuru Village',
    path: 'activities/2021-Present/2023/05-07-2023/images/',
    lastIndex: '12'
};

const tt3_seven = {
    summary: 'Provided entry fee for Para players to take part in the 5th Indian Open Para Athletics International Championship 2023.|Activity conducted at: Para sports association of Andhra Pradesh, Visakhapatnam.',
    path: 'activities/2021-Present/2023/04-25-2023/images/',
    lastIndex: '7'
};

const tt3_six = {
    summary: 'Provided sarees to poor oldaged, widowed and specially abled women.|Activity conducted at: Lam Village',
    path: 'activities/2021-Present/2023/04-21-2023/images/',
    lastIndex: '14'
};

const tt3_five = {
    summary: 'Provided gym equipment for para athletes.|Activity conducted at: Para sports association of Andhra Pradesh, Visakhapatnam.',
    path: 'activities/2021-Present/2023/04-14-2023/images/',
    lastIndex: '11',
    updates: [
        {
            updateIndex: 0,
            summary: 'Para athletes V Bhavani and K Lalitha won medals at 5th Indian Open Para Atheletics International Championship - 2023 held at Bengaluru',
            path: 'activities/2021-Present/2023/04-14-2023/updates/1/',
            lastIndex: '2'
        }
    ]
};

//Activity1: 1-4 Images, Activity2: 5,6 Images, Activity3: 7 Images, Activity4: 8,9 Images, Activity5: 10 Images
const tt3_four = {
    summary: 'Provided food supplies for one month to children at an orphanage.|Activity conducted at: Brahmana Koduru.\nProvided sweets to children at an Orphanage.|Activity conducted at: Etukuru, Guntur.\nProvided groceries and rice to an oldage home.|Activity conducted at: Tummalacheruvu village.\nProvided sewing machine to the children at St. Anns AIDS care and support center.|Activity conducted at: Machavaram.\nProvided sweets to specially abled children at St. Anns Manovikas Kendra.|Activity conducted at: Guntur.',
    path: 'activities/2021-Present/2023/03-19-2023/images/',
    lastIndex: '10'
};

const tt3_three = {
    summary: 'Provided lunch and dinner for specially abled artists.|Activity conducted at: Kotappakonda, Palnadu District.',
    path: 'activities/2021-Present/2023/02-18-2023/images/',
    lastIndex: '7'
};

const tt3_two = {
    summary: 'Felicitated war disabled soldiers and their family members.|Activity conducted at: The office of the Zilla Sainik Welfare Officer, Guntur.',
    path: 'activities/2021-Present/2023/01-26-2023/images/',
    lastIndex: '14'
};

const tt3_one = {
    summary: 'Provided sarees, lungis and towels to poor tribal people.|Activity conducted at: Madanuru village, Kothapatnam Mandal, Prakasam District.',
    path: 'activities/2021-Present/2023/01-14-2023/images/',
    lastIndex: '28'
};

const tt3_zero = {
    summary: 'Provided new clothes to ragpicker families who reside at the city dump yard.|Activity conducted at: Naidupeta, Guntur.',
    path: 'activities/2021-Present/2023/01-01-2023/images/',
    lastIndex: '24'
};

const tt4_six = {
    summary: 'Provided blankets, clothes and groceries to Gothala Swami Orphanage that takes care of specially abled people.|Activity conducted at: Ponnuru',
    path: 'activities/2021-Present/2024/07-17-2024/images/',
    lastIndex: '7'
};

const tt4_five = {
    summary: 'Provided washing machine to a child orphanage.|Activity conducted at: Etukuru',
    path: 'activities/2021-Present/2024/06-18-2024/images/',
    lastIndex: '4'
};

const tt4_four = {
    summary: 'Provided steel pots to 100 poor tribal women for carrying drinking water from water streams to their huts.|Activity conducted at: Banda, Chavadikota, Chekkuvada, Turravada, Muntamamidi, Butulavalasa and Mettulagummi thandas of ASR district',
    path: 'activities/2021-Present/2024/05-25-2024/images/',
    lastIndex: '25'
};

const tt4_three = {
    summary: 'Provided 150 sarees to widows, old aged and specially abled women.|Activity conducted at: Mallepadu and Vinukonda.',
    path: 'activities/2021-Present/2024/04-07-2024/images/',
    lastIndex: '16'
};

const tt4_two = {
    summary: 'Provided lunch and dinner to specially abled artists.|Activity conducted at: Kotappakonda.',
    path: 'activities/2021-Present/2024/03-08-2024/images/',
    lastIndex: '10'
};

const tt4_one = {
    summary: 'Provided utensils for Gothala Swami Ashram.|Activity conducted at: Ponnur.',
    path: 'activities/2021-Present/2024/02-15-2024/images/',
    lastIndex: '6'
};

const tt4_zero = {
    summary: 'Provided sarees, lungis and towels to poor people.|Activity conducted at: Hamsaladeevi.',
    path: 'activities/2021-Present/2024/01-14-2024/images/',
    lastIndex: '24'
};

const tt4 = {
    navInfo: ['14 January 2024', '15 February 2024', '08 March 2024', '07 April 2024', '25 May 2024', '18 June 2024', '17 July 2024'],
    0: tt4_zero,
    1: tt4_one,
    2: tt4_two,
    3: tt4_three,
    4: tt4_four,
    5: tt4_five,
    6: tt4_six
};

const tt3 = {
    navInfo: ['01 January 2023', '14 January 2023', '26 January 2023', '18 February 2023', '19 March 2023', '14 April 2023', '21 April 2023', '25 April 2023', '05 May 2023', '12 June 2023', '16 July 2023', '20 August 2023', '24 September 2023', '10 October 2023', '14 November 2023'],
    0: tt3_zero,
    1: tt3_one,
    2: tt3_two,
    3: tt3_three,
    4: tt3_four,
    5: tt3_five,
    6: tt3_six,
    7: tt3_seven,
    8: tt3_eight,
    9: tt3_nine,
    10: tt3_ten,
    11: tt3_eleven,
    12: tt3_twelve,
    13: tt3_thirteen,
    14: tt3_fourteen
};

const tt2 = {
    navInfo: ['01 January 2022', '14 January 2022', '01 February 2022', '11 February 2022', '01 March 2022', '02 April 2022', '14 April 2022', '01 May 2022', '05 June 2022', '18 July 2022', '23 July 2022', '11 August 2022', '15 August 2022', '05 September 2022', '03 October 2022', '05 October 2022', '24 October 2022', '25 November 2022', '01 December 2022', '03 December 2022', '23 December 2022'],
    0: tt2_zero,
    1: tt2_one,
    2: tt2_two,
    3: tt2_three,
    4: tt2_four,
    5: tt2_five,
    6: tt2_six,
    7: tt2_seven,
    8: tt2_eight,
    9: tt2_nine,
    10: tt2_ten,
    11: tt2_eleven,
    12: tt2_twelve,
    13: tt2_thirteen,
    14: tt2_fourteen,
    15: tt2_fifteen,
    16: tt2_sixteen,
    17: tt2_seventeen,
    18: tt2_eighteen,
    19: tt2_nineteen,
    20: tt2_twenty
};

const tt1 = {
    navInfo: ['11 January 2021', '05 March 2021', '26 March 2021', '11 July 2021', '21 July 2021', '08 August 2021', '05 September 2021', '24 October 2021', '31 October 2021', '04 November 2021', '19 December 2021'],
    0: tt1_zero,
    1: tt1_one,
    2: tt1_two,
    3: tt1_three,
    4: tt1_four,
    5: tt1_five,
    6: tt1_six,
    7: tt1_seven,
    8: tt1_eight,
    9: tt1_nine,
    10: tt1_ten
};

const curDecade = {
    navInfo: ['2021', '2022', '2023', '2024'],
    0: tt1,
    1: tt2,
    2: tt3,
    3: tt4
};

const firstDecade = {
    summary: 'Some of the activities conducted as part of the trust\'s first decade of operations.',
    path: 'activities/2011-2021/images/',
    lastIndex: '325'
};

const activities = {
    navInfo: ['2011-2021', '2021-Present'],
    0: firstDecade,
    1: curDecade,
};

export default activities
